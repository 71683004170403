import React from 'react';

import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import classnames from 'classnames';

import styles from './skeleton.module.scss';

const SkeletonSectionFeatured = () => {
  return (
    <div
      className={classnames(
        styles['bt-section-featured'],
        styles['skeleton-loading']
      )}
    >
      <Row>
        <Col md="6" lg="4">
          <div className={styles['section-featured-img-holder']}></div>
        </Col>
        <Col md="6" lg="8">
          <Row>
            <Col className={styles['bt-section-featured-body']}>
              <h4></h4>
              <h2></h2>
              <p></p>
              <a></a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SkeletonSectionFeatured;
