import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SectionFeatured from '../../components/SectionFeatured/SectionFeatured';
import SkeletonSectionFeatured from '../../components/SectionFeatured/Skeleton';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';


export const FetchFeatured = (params, componentInfo) => {
  let apiUrl;
  if (componentInfo?.type == 'series') {
    apiUrl = '/api/series';
  } else {
    apiUrl = '/api/summits';
  }
  const fullUrl = swrUrlParser(apiUrl, params);

  const { data, error } = useSWR(fullUrl, useSWRFetcher());

  let featuredData;

  if (error) {
    featuredData = {
      data: [],
      found: 0,
    };
  }

  if (!data || data['summits']?.length === 0) {
    featuredData = {
      data: [],
      found: 0,
    };
  } else {
    featuredData = {
      data: data['summits'],
      found: data['found'],
    };
  }

  switch (componentInfo?.type) {
    case 'series':
      return (
        <>
          {(() => {
            if (!data) {
              return (
                <>
                  <SkeletonSectionFeatured />
                </>
              );
            } else if (featuredData['data']?.length >0) {
              return (
                <>
                  <SectionFeatured
                    summitData={featuredData['data'][0]}
                    type="series"
                    sectionTitle="Series"
                    priorityStatus={componentInfo?.priorityStatus}
                    utmParams={componentInfo?.seriesUTM}
                    dataBddTitle={'featured-talk-title-series'}
                    dataBddDesc={'featured-talk-card-description-series'}
                    dataBddLearnMore={'featured-talk-learn-more-series'}
                    siteLink={componentInfo?.seriesUrl + '/series/'}
                  />
                </>
              );
            } else if (featuredData['series']?.length < 1) {
              <></>;
            }
          })()}
        </>
      );
      break;
    case 'summits':
        return (
            <>
              {(() => {
                if (!data) {
                  return (
                    <>
                     
                      <SkeletonSectionFeatured />
                    </>
                  );
                } else if (featuredData['data']?.length >0) {
                  return (
                    <>
                      <SectionFeatured
                        summitData={featuredData['data'][0]}
                        type="summits"
                        sectionTitle="Summit"
                        utmParams={componentInfo?.summitsUTM}
                        dataBddTitle={'featured-talk-title-summits'}
                        dataBddDesc={'featured-talk-card-description-summits'}
                        dataBddLearnMore={'featured-talk-learn-more-summits'}
                        siteLink={componentInfo?.summitUrl + '/summit/'}
                        priorityStatus={componentInfo.priorityStatus}
                    />
                    </>
                  );
                } else if (featuredData['data']?.length < 1) {
                  <></>;
                }
              })()}
            </>
          );
      break;
  }
};

export default FetchFeatured;
